import React from 'react'
import "../css/Work.css";
import l5photo from "../img/L5_Facebook_Cover.png";
import data from '../data/data.json'
import Reference from "../components/Reference"

function Work() {
    return (
        <div className="Work">
            <div className="Work-header">
                <div className="big-photo">
                    <img src={l5photo} className="l5-photo-work" alt="l5-logo" />
                </div>
            </div>
            <div className="Work-body">
                <div className="Work-intro">
                    <h1 className="intro-title">{data.work["title"]}</h1>
                    <p className="work-text">{data.work["work-text"]}</p>
                </div>
                <div className="references">
                    <Reference src={data.photos["photo-2"]} />
                    <Reference src={data.photos["photo-3"]} />
                    <Reference src={data.photos["photo-2"]} />
                    <Reference src={data.photos["photo-3"]} />
                </div>
            </div>
        </div>
    );
}

export default Work;