import React from 'react'
import "../css/Contact.css";
import l5photo from "../img/L5_Facebook_Cover.png";
import data from '../data/data.json'
import {BsTelephone} from 'react-icons/bs'
import {AiOutlineMail} from 'react-icons/ai'

function Contact() {
    return (
        <div className="Contact">
        <div className="Contact-header">
            <div className="big-photo">
                <img src={l5photo} className="l5-photo-contact" alt="l5-logo" />
            </div>
        </div>
        <div className="Contact-body">
            <div className="Contact-intro">
                <h1 className="intro-title">{data.contact["title"]}</h1>
                <p>We'd love to hear from you!</p>
            <div className="contact-info">
                {/* <p className="phone"><BsTelephone /> &nbsp; Phones</p>
                <p> +45 25 68 57 45 | Jonas</p>
                <p> +45 50 50 62 18 | Rasmus</p> */}
                <p className="email"><AiOutlineMail /> &nbsp; Mail</p>
                <a className="email-text" href="mailto:info@labelandfifth.com">info@labelandfifth.com</a>
            </div>
            </div>
        </div>
    </div>
    );
}

export default Contact;