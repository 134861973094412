import React from 'react'
import '../css/FooterBar.css'
import { FaInstagram, FaFacebook } from 'react-icons/fa';

function FooterBar() {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-medias text-center px-2">
                    <h1>
                        <a className="footer-facebook" href ="https://www.facebook.com/labelandfifth">
                            <FaFacebook />
                        </a>
                        <a className="footer-instagram" href="https://www.instagram.com/labelandfifth/" >
                            <FaInstagram/>
                        </a>
                    </h1>
                </div>
                <div className="footer-copyright text-center py-3">© 2022 Copyright - Label & Fifth</div>
            </div>
        </footer>
    );
}

export default FooterBar;