import React from 'react'
import "../css/Services.css";
import l5photo from "../img/L5_Facebook_Cover.png";
import data from '../data/data.json'

function Services() {
    return (
        <div className="Services">
            <div className="Services-header">
                <div className="big-photo">
                    <img src={l5photo} className="l5-photo-services" alt="l5-logo" />
                </div>
            </div>
            <div className="Services-body">
                <div className="Services-intro">
                    <h1 className="intro-title">{data.services["title"]}</h1>
                    <p className="services-text">{data.services["services-text"]}</p>
                    <div className="points-wrapper">
                        <ul className="services-points">
                            <li>{data.services["services-1"]}</li>
                            <li>{data.services["services-2"]}</li>
                            <li>{data.services["services-3"]}</li>
                            <li>{data.services["services-4"]}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;