import React from 'react'
import l5logo from "../img/l5-logo.png";
import "../css/Navbar.css";
import { Nav, Navbar, Container } from "react-bootstrap";

function NavBar() {
  return (
    <div className="">
      <Navbar
        className="py-4 px-3"
        collapseOnSelect
        expand="lg"
        bg="black"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <img className="nav-logo" src={l5logo} height="60px" alt="l5-logo"/>
            LABEL & FIFTH
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/">HOME</Nav.Link>
              <Nav.Link href="/services">SERVICES</Nav.Link>
              <Nav.Link href="/work">WORK</Nav.Link>
              <Nav.Link href="/contact">CONTACT</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
export default NavBar;