import './App.css';
import NavBar from './components/Navbar'
import Home from './components/Home'
import Services from './components/Services'
import Work from './components/Work'
import Contact from './components/Contact'
import FooterBar from './components/FooterBar'
import Middlepage from './components/Middlepage'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  return (
    <Router>
      <div className="App">
        <div className="content">
          <NavBar />
          <Routes>
            <Route exact path="/qr" element={<Middlepage />} />
            <Route exaxt path="/" element={<Home />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/work" element={<Work />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem", color: "white", textAlign: "center" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Routes>
        </div>
      </div>
      <FooterBar />
    </Router>
  );
}
export default App;