import React from 'react'
//import data from '../data/data.json'

function Middlepage() {
    return (
      <div className="Middlepage">
        <div className="Middlepage-header">
        </div>
        <div className="Middlepage-body">
          <div className="intro-text">
            <h1>HEJSDJASJASJ</h1>
          </div>
        </div>
      </div>
    );
  }
  export default Middlepage;
  