import React from 'react'
import "../css/Home.css";
import l5photo from "../img/L5_Facebook_Cover.png";
import data from '../data/data.json'
import Reference from "../components/Reference"

function Home() {
  return (
    <div className="Home">
      <div className="Home-header">
        <div className="big-photo">
          <img src={l5photo} className="l5-photo-home" alt="l5-logo"/>
        </div>
      </div>
      <div className="Home-body">
        <div className="intro-text">
          <h1 className="intro-title">{data.home["intro-title"]}</h1>
          <span className="home-text">{data.home["about-text"]}</span>
        </div>
        <div className="references">
          <Reference src={data.photos["photo-2"]} />
          <Reference src={data.photos["photo-3"]} />
          <Reference src={data.photos["photo-2"]} />
          <Reference src={data.photos["photo-3"]} />
        </div>
      </div>
    </div>
  );
}
export default Home;
