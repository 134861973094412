import React from 'react'
import "../css/Reference.css";

const Reference = (props) => {
  return (
    <div className="reference-card mx-auto">
      <div className="Container">
        <div className="reference">
          <img src={props.src} className="reference-photo" alt=""></img>
          <h1 className="image-text">Reference</h1>
        </div>
        <div className="reference-overlay">
          <div className="reference-wrapper">
            <div className="row">
              <div className="col">
                <h2>Production</h2>
              </div>
              <div className="col">
                <h2>Services</h2>
              </div>
              <div className="col">
                <h2>Project</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reference;
